
import $app from '@/plugins/modules';
import { IDamage } from '@/modules/clinic/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ISimpleDataItem } from '@/modules/system/types';
import { ITooth, IToothDamage } from '../types';

@Component
export default class ToothDamagesDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => null })
  readonly tooth: ITooth | undefined;

  @Prop({ default: () => { } })
  readonly exclude: any;

  damages: Array<IDamage> = [];
  groups: Array<ISimpleDataItem> = [];

  showMenu = false;
  selectedDamage: IDamage | null = null;
  surfaceM = false;
  surfaceD = false;
  surfaceV = false;
  surfaceL = false;
  surfaceO = false;

  filter = '';
  group = 0;

  @Watch('value')
  async onShow() {
    if (this.value) {
      try {
        this.damages = await $app.get('/api/clinics/damages');
        this.groups = await $app.get('/api/clinics/damages/groups');
      } catch (err) {
        $app.pushError(err);
      }
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get items(): Array<IDamage> {
    return this.damages.filter(d => {
      return (this.filter === '' ||
        d.code.toUpperCase().includes(this.filter.toUpperCase()) ||
        d.name.toUpperCase().includes(this.filter.toUpperCase())
      ) && (this.group === 0 || d.group === this.group) &&
        (!this.exclude || !this.exclude[d.code])
    }).sort((a, b) => {
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  }

  toToothDamage(damage: IDamage): IToothDamage {
    const surfaces: Array<string> = [];

    if (this.surfaceM) surfaces.push('M');
    if (this.surfaceD) surfaces.push('D');
    if (this.surfaceV) surfaces.push('V');
    if (this.surfaceL) surfaces.push('L');
    if (this.surfaceO) surfaces.push('O');

    return {
      id: 0,
      damageId: damage.id!,
      code: damage.code,
      name: damage.name,
      damageGum: damage.gum,
      damageSurface: damage.surface,
      mode: damage.mode,
      number: this.tooth!.number,
      surfaces,
      damage,
      processing: false,
      appended: true
    }
  }

  applySurfaces(damage: IDamage) {
    this.showMenu = false;
    this.$emit('select', this.toToothDamage(damage));
  }

  select(damage: IDamage) {
    this.surfaceM = false;
    this.surfaceD = false;
    this.surfaceV = false;
    this.surfaceL = false;
    this.surfaceO = false;

    if (!damage.surface) {
      this.$emit('select', this.toToothDamage(damage));
    } else {
      this.selectedDamage = damage;
      this.showMenu = true;
    }
  }
}
